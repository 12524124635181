import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {configureApi, apiToken, apiUrl, executeQuery} from './_searchCommon';

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    searchSorting: 'score',
    isLoading: true,
    isReady: false,
    arrowCount: 0,
    isSearchDropdownOpen: false,
    isSearchFocused: false,
    window: {
      width: 0,
      height: 0
    },
    site: _.get(window, 'craftData.site', window.siteHandle),
  };
}

Vue.filter('highlight', function(words, query, className) {
  const reg = new RegExp(query.trim(), 'gi');
  if (!words) {
    words = '';
  }
  return words.replace(reg, function(str) {return '<span class="' + className + '">' + str + '</span>'})
});

var filterChangeTimer;

var generalSearch = function() {

// What to search for
const searchEntries = ['NewsArticle', 'NewsEvent', 'PeoplePerson', 'OfficesOffice', 'PagesPage', 'PagesContact', 'PagesHomepage', 'PagesParentPage', 'PagesLandingPage', 'PagesNewsLanding', 'PagesSearchengine'];
let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        postDate
        url
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($site: String, $needle: String, $limit: Int, $offset: Int, $orderBy: String)
    {
      entriesConnection( site: $site limit: $limit offset: $offset orderBy: $orderBy search: $needle) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          currentPage
          totalPages
          first
          last
        }
        entries{
          ${searchQueries}
        }
      }
    }
  `;

  new Vue({
    el: document.getElementById('generalSearch'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
    },
    beforeCreate: function() {
    },
    created: function() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    mounted: function() {
      document.addEventListener('click', this.closeSearchDropdown);
    },
    updated: function() {
    },
    destroyed: function() {
      document.removeEventListener('click', this.closeSearchDropdown);
      window.removeEventListener('resize', this.handleResize);
    },
    watch: {
      searchQuery: function(val, oldVal) {
        this.performSearch();
      },
      isSearchDropdownOpen: function(val, oldVal) {
      console.log("TCL: val", val)
        if(val && !oldVal) {
          document.addEventListener('keydown', this.arrowCounter);
        } else {
          document.removeEventListener('keydown', this.arrowCounter);
        }
      },
      isLoading: function(val, oldVal) {
        if(!val && oldVal) {
          this.arrowCount = 0;
        }
      },
      isSearchFocused: function(val, oldVal) {
        if(val) {
          document.addEventListener('mouseup', this.toggleSearchFocused);
        }
      }
    },
    filters: {
    },
    computed: {
    },
    methods: {
      updateInput(e) {
        console.log("TCL: updateInput -> e", e)
        this.searchQuery = e;
        this.isSearchDropdownOpen = true;
        document.getElementById("dropdown-search").focus();
      },
      toggleSearchFocused(e) {
        if(e.target.id !== "dropdown-search") {
          this.isSearchFocused = false;
          document.removeEventListener('mouseup', this.toggleSearchFocused);
        }
      },
      performSearch() {
        var self = this;

        if(self.searchQuery === '') {
          self.searchResults = [];
          self.isSearchDropdownOpen = false;
          return true;
        }

        self.isLoading = true;
        const searchTitle = !!self.searchQuery ? `title:"${self.searchQuery}"` : '';
        const searchCategory = !!self.searchQuery && `categories:"${self.searchQuery}" OR categories::"${self.searchQuery}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;

        const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          site: self.site,
          needle: searchString,
          limit: 5,
          orderBy: self.searchSorting,
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data.entriesConnection;
            self.searchResults = dataPath.entries;
            self.isSearchDropdownOpen = true;

            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      closeSearchDropdown: function(event) {
        if (!event.target.classList.contains('letSearchDropdownOpen') && !event.target.classList.contains('preventClose')) {
          this.isSearchDropdownOpen = false;
        }
      },
      arrowCounter: function(event) {
        const self = this;
        switch (event.which) {
          case 38 : // Arrow up
            if(self.arrowCount > 0) {
              self.arrowCount = self.arrowCount - 1;
            }
          break;
          case 40 : // Arrow down
            if(self.arrowCount < this.searchResults.length + 1) {
              self.arrowCount = self.arrowCount + 1;
            }
          break;
          case 27 : // ESC
            self.arrowCount = 0;
            self.isSearchDropdownOpen = false;
          break;
          case 13 : // Enter
            if(self.arrowCount === 0 || self.arrowCount === this.searchResults.length + 1 ) {
              self.goToSearchLanding();
            }else {
              const selectedUrl = this.searchResults[self.arrowCount - 1];
              window.location.href = selectedUrl.url;
            }
          break;
        }
      },
      goToSearchLanding() {
        const url = this.site === 'sv' ? 'sv/search' : this.site === 'et' ? 'ee/search' : this.site === 'se' ? 'se/search' : 'search';
        window.location.href = `/${url}?q=` + this.searchQuery;
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
    },
  });
};

!!document.getElementById('generalSearch') && generalSearch();
