<template>
  <transition name="fadeIn" mode="in-out">
    <li v-show="!isLoading">
      <div class="search-page-item">
        <div class="search-page-item__column">
          <div class="search-page-item__row">
            <div class="image-wrapper" v-if="!!item.image && !!item.image[0] || !!item.featuredImage && !!item.featuredImage[0]">
              <a class="object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5" :href="item.url" :title="item.title">
                <img
                  class="lazyload"
                  :src="!!item.image && !!item.image[0] ? item.image[0].url : !!item.featuredImage && !!item.featuredImage[0] ? item.featuredImage[0].url : '/assets/img/placeholder.jpg'"
                  :alt="!!item.image && !!item.image[0] ? item.title : !!item.featuredImage && !!item.featuredImage[0] ? item.title : ''"
                  data-sizes="auto"
                  onerror="this.src = '/assets/img/placeholder.jpg';"
                >
              </a>
            </div>
            <div class="info-wrapper">
              <div class="info-wrapper__section">
                <span v-if="!!item.section" style="margin-right: -4px;">{{ item.section.name|trans }}</span>
              </div>
              <a class="" :href="item.url" :title="item.title">
                <h4 class="heading-4">
                  {{!!item.title && item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}
                </h4>
              </a>
              <div v-if="item.type.name === 'Person' || item.type.name === 'Office'" class="mb-2">
                <a :v-if="phoneNumber" :href="'tel:' + phoneNumber" class="person-card__phone js-track-link" data-track-category="phone">{{ item.phoneNumber }}</a>
                <a :v-if="!!item.email && item.email.length > 0" :href="'mailto:' + item.email" class='person-card__email js-track-link' data-track-category='email'>{{ item.email }}</a>
              </div>
              <div v-if="!!item.categories || (!!item.section && item.section.name === 'News')" class="info-wrapper__categories mt-auto">
                <span v-if="item.section.name === 'News'" class="text-xs">
                  {{ item.postDate | unixToIso }}
                  <span v-if="!!item.categories && item.categories.length" class="text-blue-light mx-1">|</span>
                </span>
                <template
                  v-if="!!item.categories"
                >
                  <span 
                    v-for="(cat, key) in item.categories.slice(0, 2)"
                    :key="key"
                    class="text-xs">{{ cat.title }}<span v-if="key < item.categories.slice(0, 2).length - 1" class="inline-block pr-1">,</span></span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

// moment.locale('sv');

export default {
  name: 'SearchPageItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  computed: {
    phoneNumber: function () {
      return !!this.item.phoneNumber ? this.item.phoneNumber.replace('/[^0-9\+]/', '').replace(/ /g, '') : ''
    } 
  },
  filters: {
    unixToIso: function(unix_timestamp) {
      return moment.unix(unix_timestamp).format('DD MMM YYYY');
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM');
        break;
      }
    }
  }
};
</script>
