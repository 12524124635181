import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import transFilter from 'vue-trans';

import {configureApi, apiToken, apiUrl, getQueryParam, executeQuery} from './_searchCommon';
import CategorySearchMeta from '../vue/CategorySearchMeta';
import SearchPageList from '../vue/SearchPageList';
import Pagination from '../vue/Pagination';

Vue.use(transFilter);

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    searchSorting: 'postdate desc',
    meta: {
      totalPages: 1,
      currentPage: 1,
      elementsPerPage: 24,
      totalCount: 0,
      offset: 0
    },
    isLoading: true,
    isReady: false,
    site: _.get(window, 'craftData.site', window.siteHandle),
  };
}

var filterChangeTimer;

var generalSearch = function() {

// What to search for
const searchEntries = ['NewsArticle', 'NewsEvent', 'PeoplePerson', 'OfficesOffice', 'PagesPage', 'PagesContact', 'PagesHomepage', 'PagesParentPage', 'PagesLandingPage', 'PagesNewsLanding', 'PagesSearchengine'];
let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        section {
          name
        }
        type {
          name
        }
        url${entryType === 'NewsArticle' ? `
          featuredImage {
            ...on GeneralVolume {
              url
              title
            }
          }
          newsCategories {
            title
          }

        ` : entryType === 'PagesPage' ?`
          featuredImage {
            ...on GeneralVolume {
              url
              title
            }
          }
        ` : entryType === 'PeoplePerson' ?`
          phoneNumber
          email
          image {
            ...on GeneralVolume {
              url
            }
          }
        ` : entryType === 'OfficesOffice' ?`
          phoneNumber
          email
        ` : ''}
        postDate
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($site: String, $needle: String, $limit: Int, $offset: Int, $orderBy: String)
    {
      entriesConnection( site: $site limit: $limit offset: $offset orderBy: $orderBy search: $needle) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          currentPage
          totalPages
          first
          last
        }
        entries{
          ${searchQueries}
        }
      }
    }
  `;

  new Vue({
    el: document.getElementById('searchPage'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      SearchPageList,
      CategorySearchMeta,
      Pagination,
    },
    beforeCreate: function() {
    },
    created: function() {
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if(!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if(!!categoryIdParam) {
        this.searchCategory.id = categoryIdParam;
      }
      if(!!pageParam) {
        this.meta.currentPage = parseInt(pageParam);
      }
    },
    mounted: function() {
      this.performSearch();
    },
    updated: function() {
    },
    destroyed: function() {
    },
    watch: {
      'meta.currentPage': function(val) {
        this.scrollup();
        this.meta.offset = this.meta.elementsPerPage * (val - 1);
        this.performSearch();
      },
      searchQuery: function(val, oldVal) {
        if(!!oldVal && val !== oldVal) {
          this.meta = _.get(getcategorySearchLandingData(), 'meta');
        }
        this.performSearch();
      },
      searchSorting: function() {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      sortSearch(data) {
        const entries = _.get(data, 'data.entriesConnection.entries');
        if (!!entries) {
          _.forEach(entries, function(entry) {
            const category = _.get(entry, 'newsCategory') || _.get(entry, 'productCategory');
            if(!!category) {
              entry.categories = category;
            }
          })
        }
      },
      performSearch() {
        var self = this;

        if(self.searchQuery === '') {
          self.searchResults = [];
          self.isSearchDropdownOpen = false;
          return true;
        }

        self.isLoading = true;
        self.setHistory();
        const searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}*` : '';
        const searchCategory = !!self.searchQuery && `categories:"${self.searchQuery}" OR categories::"${self.searchQuery}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery}`;

        const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          site: self.site,
          needle: searchString,
          limit: self.meta.elementsPerPage || 24,
          offset: self.meta.offset || 0,
          orderBy: self.searchSorting,
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data.entriesConnection;
            self.searchResults = dataPath.entries;
            self.meta.totalCount = dataPath.totalCount;
            self.meta.totalPages = dataPath.pageInfo.totalPages;
            self.meta.hasPreviousPage = dataPath.pageInfo.hasPreviousPage;
            self.meta.hasNextPage = dataPath.pageInfo.hasNextPage;

            self.sortSearch(data);

            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function() {
        var self = this;
        var paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if(!!self.meta.currentPage) {
          paramString += !!paramString ? ('&page=' + self.meta.currentPage) : (paramString += '?page=' + self.meta.currentPage);
        }
        if (window.history && window.history.replaceState) {
          var pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          var url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function() {
        var top = 0;

        setTimeout(function() {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
    },
  });
};

!!document.getElementById('searchPage') && generalSearch();
