var resizingClass = function () {
  "use strict";
  var resizeTimer,
    $body = $("body"),
    resizingClass = "resizing";

  $(window).on("resize", function (e) {
    if (!$body.hasClass(resizingClass)) {
      $body.addClass(resizingClass);
    }
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      $body.removeClass(resizingClass);
      equalizerReflow();
      $body.trigger("resized");
    }, 250);
  });
};

var inputFocus = function () {
  "use strict";
  $("input")
    .on("focus", function () {
      $(this)
        .parent()
        .addClass("focusing");
      if (
        $(this)
          .parent()
          .hasClass("input")
      ) {
        $(this).keydown(function (event) {
          if (event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        });
      }
    })
    .on("blur", function () {
      $(this)
        .parent()
        .removeClass("focusing");
    });
};

var styledSelect = function () {
  "use strict";
  $("select.styled").each(function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();

    if ($this.parent(".select-wrapper").length === 0) {
      $this.wrap("<div class='select-wrapper'></div>");
      $this.after("<span class='holder'>" + selectedOption + "</span>");
    } else {
      $this.next(".holder").text(selectedOption);
    }
  });

  $(document).on("change", "select.styled", function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();

    $this.next(".holder").text(selectedOption);
  });
};

var toggleSearch = function () {
  "use strict";
  var $body = $("body"),
    openClass = "header-search-open",
    $input = $(".header-search input"),
    closeSearch = function () {
      $body.removeClass(openClass);
      // $input.blur();
    },
    openSearch = function () {
      $body.addClass(openClass);
      // $input.focus();
    };

  $(".toggle-search").on("click", function (e) {
    e.preventDefault();
    if ($body.hasClass(openClass)) {
      closeSearch();
    } else {
      openSearch();
    }
    return false;
  });

  $(window).resize(function () {
    if ($body.hasClass(openClass)) {
      closeSearch();
    }
  });

  $(document).on("click", function (e) {
    if (!$(e.target).is(".header-search, .header-search *")) {
      $body.removeClass(openClass);
    }
  });
};

var headerDropdown = function () {
  "use strict";
  $(".main-navigation .has-submenu").hoverIntent({
    over: function () {
      $(this).addClass("open");
    },
    out: function () {
      $(this).removeClass("open");
    },
    timeout: 400
  });
};

var equalizerReflow = function () {
  "use strict";
  var $equalizers = $("[data-equalizer]");
  $.each($equalizers, function () {
    new Foundation.Equalizer($(this));
  });
};

var styledCheckboxAndRadio = function () {
  "use strict";
  var $inputs = $('input[type="checkbox"], input[type="radio"]');
  $inputs.parent("label").addClass("styled");
  $inputs.after('<span class="dummy"></span>');
};

var heroBlur = function () {
  "use strict";
  if ($(".hero--blurred").length) {
    var $window = $(window),
      once = true,
      $hero = $(".hero--blurred"),
      limit = 30,
      toggleBlurred = function () {
        var windowTop = $window.scrollTop();

        if (windowTop > limit) {
          once = false;
          $hero.addClass("blurred");
        } else {
          once = true;
          $hero.removeClass("blurred");
        }
      };

    setInterval(toggleBlurred, 10);
  }
};

var questionnaire = function () {
  "use strict";
  if ($(".questionnaire-form").length) {
    var $input = $(".custom-checkbox"),
      $button = $(".questionnaire-button"),
      $last = $(".last-checkbox");

    $input.on("click", function () {
      $(this).toggleClass("check");
      $(this)
        .parent()
        .next()
        .removeClass("hidden");
      if ($input.length === $(".check").length) {
        $button.removeClass("hidden-button");
      } else {
        $button.addClass("hidden-button");
      }
    });
  }
};

var searchEngineBanner = function () {
  "use strict";
  $(".searchengine-banner__field input").on("change", function () {
    var $this = $(this),
      $container = $this.parents(".searchengine-banner"),
      baseUrl = $container.data("url"),
      $button = $container.find(".searchengine-banner__cta .button"),
      param = $this.serialize(),
      redirectUrl = baseUrl + "?" + param;

    $button.attr("href", redirectUrl);
  });
};

var cookieNotification = function () {
  "use strict";
  var $notification = $(".cookie-notification");
  $notification.find(".close-button").on("click", function () {
    $notification.addClass("cookie-notification--closed");
  });
};

// Fix for blurry iframe embed thumbnail images inside of accordions
var loadIframe = function () {
  "use strict";
  var $iframe = $('iframe');

  if ($('.accordion').length) {
    if ($iframe.length) {
      $('.accordion-title').on("click", function () {
        $iframe.attr('src', $iframe.attr('src'));
      });
    }
  }
};

var dropdownFunction = function (toggleButtonSelector, dropdownSelector, closeButtonSelector) {
  var toggleButtons = document.querySelectorAll(toggleButtonSelector);
  var dropdown = document.querySelector(dropdownSelector);
  var searchInput = document.querySelector('#dropdown-search');

  var toggleDropdown = () => {
    if (dropdown.style.display === 'block') {
      dropdown.style.display = "none";
      document.removeEventListener('click', closeDropdown);
    } else {
      dropdown.style.display = 'block';
      document.addEventListener('click', closeDropdown);
      if ($('.main-navigation').css('display') === 'inline-block') {
        searchInput.focus();
      }

      if (dropdownSelector == '#search-dropdown') {
        document.getElementById('dropdown-search').focus();
      }
    }
  }
  var closeDropdown = (event) => {
    if (event.target.closest(toggleButtonSelector) || event.target.classList.contains('preventClose')) return;
    if (!event.target.closest(dropdownSelector) || !!event.target.closest(closeButtonSelector)) {
      dropdown.style.display = "none";
      document.removeEventListener('click', closeDropdown);
    }
  }

  _.forEach(toggleButtons, function (toggleButton) {
    toggleButton.addEventListener('click', toggleDropdown);
  });
}

import Rellax from 'rellax';
var imageParallax = function () {
  // Also can pass in optional settings block
  var rellax = new Rellax('.rellax', {
    wrapper: null,
    speed: -2,
    center: true,
    round: true,
    vertical: true,
    horizontal: false,
    breakpoints: [576, 768, 1201]
  });
}

$(document).ready(function () {
  "use strict";
  $(document).foundation();
  $("input, textarea").placeholder();
  inputFocus();
  styledSelect();
  styledCheckboxAndRadio();
  toggleSearch();
  headerDropdown();
  questionnaire();
  searchEngineBanner();
  cookieNotification();
  loadIframe();
  imageParallax();
  dropdownFunction('.toggle-nav-dropdown', '#nav-dropdown');
});

$(window).on("load", function () {
  "use strict";
  equalizerReflow();
  setTimeout(function () {
    $("body").removeClass("loading");
    equalizerReflow();
  }, 600);
  resizingClass();
  heroBlur();
});
