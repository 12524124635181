<template>
  <div class="search-sorting">
    <div class="title font-normal text-blue-dark pr-3">{{ sortByText }}</div>
    <div class="dropdown-wrapper">
      <select
      class="styled"
        :sorting.sync="sorting"
        @change="$emit('update:sorting', $event.target.value)"
      >
        <option v-for="(option, value) in options" :key="value" :value="option.value" :selected="option.value === sorting">{{ option.title }}</option>
      </select>
      <!-- <div class="arrow">
        <span>
          <svg viewBox="0 0 768 768">
            <path fill="none" stroke-linejoin="miter" stroke-linecap="square" stroke-miterlimit="4" stroke-width="96" d="M95.999 240l288.001 288.001 288.001-288.001"></path>
          </svg>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'SearchSorting',
  props: {
    sorting: {
    },
    options: {
    },
    sortByText: {
      type: String,
      default: ''
    }
  },
  options: {
    type: Object,
    default: [],
    required: true,
  }
};
</script>
