import Typed from 'typed.js';

var heroAnimation = function () {
  "use strict";
  if ($('.type-homepage').length && $('#typed').length) {
    // console.log('homepage');

    var strings = $('.type-wrap[data-strings]').attr('data-strings');
    var stringsArray = strings.split(', ');

    var typeWriter = new Typed('#typed', {
      strings: stringsArray,
      typeSpeed: 45,
      backSpeed: 20,
      backDelay: 2500,
      startDelay: 400,
      showCursor: false,
      smartBackspace: true,
      fadeOut: false,
      loop: true,
    });

  }
};

$(document).ready(function () {
  "use strict";
  heroAnimation();
});
