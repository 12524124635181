var reCenterMap = function(map, latlng, offsetx, offsety) {
  "use strict";

  var point1 = map
      .getProjection()
      .fromLatLngToPoint(
        latlng instanceof google.maps.LatLng ? latlng : map.getCenter()
      ),
    point2 = new google.maps.Point(
      (typeof offsetx == "number" ? offsetx : 0) / Math.pow(2, map.getZoom()) ||
        0,
      (typeof offsety == "number" ? offsety : 0) / Math.pow(2, map.getZoom()) ||
        0
    );

  map.setCenter(
    map
      .getProjection()
      .fromPointToLatLng(
        new google.maps.Point(point1.x - point2.x, point1.y - point2.y)
      )
  );
  google.maps.event.trigger(map, "resize");
};

var relativeOffset = function($container, $element) {
  "use strict";
  var containerWidth = $container.outerWidth(),
    containerHeight = $container.outerHeight(),
    containerX = $container.offset().left,
    containerY = $container.offset().top,
    containerXcenter = containerX + containerWidth / 2,
    containerYcenter = containerY + containerHeight / 2,
    offsetX = $element.offset().left - containerXcenter,
    offsetY = $element.offset().top - containerYcenter,
    outputArray = {
      x: offsetX,
      y: offsetY
    };

  return outputArray;
};

var getCurrent = function(map, $marker, $container) {
  "use strict";
  var currentSelector = $container.find(".map").data("current"),
    $current = $container.find(currentSelector),
    currentLat = $current.data("lat"),
    currentLng = $current.data("lng"),
    currentLatLng = new google.maps.LatLng(currentLat, currentLng),
    $markerPosition = $marker.find(".map-center-here"),
    offsetX = relativeOffset($container, $markerPosition).x,
    offsetY = relativeOffset($container, $markerPosition).y;

  reCenterMap(map, currentLatLng, offsetX, offsetY);
};

var initMap = function() {
  "use strict";
  var mapClass = ".map";
  if ($(mapClass).length) {
    $.each($(mapClass), function() {
      var $map = $(this),
        containerSelector = $map.data("container"),
        $container = $map.parents(containerSelector),
        markerSelector = $map.data("markers"),
        $markers = $container.find(markerSelector),
        $currentMarkerElement = $container.find(".office-marker"),
        $markerTitle = $currentMarkerElement.find(".office-marker__title"),
        mapOptions = {
          backgroundColor: "#f5f5f5",
          zoom: 20,
          minZoom: 6,
          maxZoom: 17,
          scrollwheel: false,
          mapTypeControl: false,
          streetViewControl: false,
          panControl: false,
          overviewMapControl: false,
          rotateControl: false,
          scaleControl: false,
          styles: [
            {
              elementType: "geometry",
              stylers: [{ saturation: -100 }]
            }
          ],
          zoomControl: false
        },
        map = new google.maps.Map($map[0], mapOptions);

      //Set the current marker information

      google.maps.event.addListenerOnce(map, "idle", function() {
        getCurrent(map, $currentMarkerElement, $container);
        setTimeout(getCurrent(map, $currentMarkerElement, $container), 2000);
      });

      //Set the new marker information
      $markers.hoverIntent({
        over: function() {
          if (Foundation.MediaQuery.atLeast("medium")) {
            var $this = $(this),
              currentSelector = $map.data("current"),
              activeClass = currentSelector.replace(".", ""),
              currentUrl = $this.data("markerurl"),
              currentTitle = $this.data("markertitle");

            if (!$this.hasClass(activeClass)) {
              $markers.removeClass(activeClass);
              $this.addClass(activeClass);
              $markerTitle.html(currentTitle);
              $currentMarkerElement.attr("href", currentUrl);
              getCurrent(map, $currentMarkerElement, $container);
            }
          }
        },
        out: function() {},
        timeout: 250
      });

      $("body").on("resized", function() {
        getCurrent(map, $currentMarkerElement, $container);
      });
    });
  }
};

window.initMap = initMap;
