var toggleNavigation = function() {
  "use strict";
  var $body = $('body'),
      openClass = 'menu-open',
      lockScreenClass = 'lock-screen',
      currentScrollPosition = 0,
      moveNav = function($button, next) {
        var $target = $($button.data('target')),
            containerSelector = $button.data('container'),
            $container = $button.parents(containerSelector),
            left = Math.round(parseInt($container[0].style.left)) || 0,
            $newHeight = (next) ? $target.outerHeight() : $target.parents('ul').outerHeight();

        if (next) {
          $target.addClass('open');
        }

        $container.height($newHeight);

        $container.stop(true, true).animate({
          'left': -Math.abs(next ? left - 100 : left + 100) + '%'
        }, 300, function() {
          if (!next) {
            $target.removeClass('open');
          }
        });
      },
      showHideNav = function() {
        if (Foundation.MediaQuery.atLeast('medium')) {
          if($body.hasClass(openClass)) {
            $body.removeClass(lockScreenClass);
            $body.removeClass(openClass);
          }
        }
      };

  $('.menu-toggle').on('click', function(e) {
    e.preventDefault();
    if($body.hasClass(openClass)) {
      $body.removeClass(lockScreenClass);
      $body.removeClass(openClass);
      $('html, body').scrollTop(currentScrollPosition);
    } else {
      currentScrollPosition = $(window).scrollTop();
      $body.addClass(openClass);
      setTimeout(function() {
        $body.addClass(lockScreenClass);
      },400);
    }
    return false;
  });

  $('.next-nav').on('click', function(e) {
    e.preventDefault();

    moveNav($(this), true);

    return false;
  });

  $('.prev-nav').on('click', function(e) {
    e.preventDefault();

    moveNav($(this), false);

    return false;
  });

  showHideNav();

  $(window).resize(function() {
    showHideNav();
  });


};


$(document).ready(function() {
  "use strict";
  toggleNavigation();
});
