<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader" v-show="isLoading || !isReady" leave-stagger="500">
      <div class="cardLoader__wrapper">
        <div class="cardLoader__wrapper__level1">
          <div class="cardLoader__wrapper__level2">
            <div class="cardLoader__image-wrapper">
              <div class="cardLoader__image-box object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5">
                <div class="cardLoader__image"></div>
              </div>
            </div>
            <div class="cardLoader__lines-wrapper">
              <div class="categories mb-1">
                <span>
                  <div class="line-micro"></div>
                </span>
              </div>
              <h3 class="line-medium-large"></h3>
              <div class="date">
                <div class="line-medium"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'EventCardLoadingIndicator',
  props: ['isLoading', 'isReady']
};
</script>
