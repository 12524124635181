var fetchVideo = function () {
  "use strict";
  var videoLink = $('.js-embed-video');

  videoLink.on('click', function (e) {
    e.preventDefault();
    var $this = $(this),
      getParm = function (url, base) {
        var re = new RegExp("(\\?|&)" + base + "\\=([^&]*)(&|$)"),
          matches = url.match(re);
        if (matches) {
          return (matches[2]);
        } else {
          return ("");
        }
      },
      parseVideoURL = function (url) {
        var retVal = {},
          matches;
        if (url.indexOf("youtube.com/watch") !== -1) {
          retVal.provider = "youtube";
          retVal.id = getParm(url, "v");
        } else {
          matches = url.match(/vimeo.com\/(\d+)/);
          retVal.provider = "vimeo";
          retVal.id = matches[1];
        }
        return (retVal);
      },
      video = parseVideoURL($this.attr('href')),
      videoEmbed,
      videoExists = false;

    if (video.provider === 'youtube') {
      videoEmbed = '<iframe src="//www.youtube.com/embed/' + video.id + '?autoplay=1" frameborder="0" allowfullscreen allow="autoplay;" rel="0"></iframe>';
      videoExists = true;
    } else if (video.provider === 'vimeo') {
      videoEmbed = '<iframe src="//player.vimeo.com/video/' + video.id + '?autoplay=1" frameborder="0" webkitAllowFullScreen mozallowfullscreen autoPlay allowFullScreen></iframe>';
      videoExists = true;
    }

    if (videoExists) {
      $this.parents('.mediaContainer__placeholder').html(videoEmbed);
    }

    return false;
  });

};

$(document).ready(function () {
  "use strict";
  fetchVideo();
});
